.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.waveform ::part(region-handle-right), .waveform ::part(region-handle-left) {
  border: none !important;
}

.waveform ::part(region) {
  position: absolute; /* Position the regions absolutely within their parent */
  top: 50%; /* Start at the vertical center of the parent */
  height: calc(100% - 300px); /* Adjust the height as needed, subtracting some value to be slightly longer than the waveform */
  transform: translateY(-50%); /* This will center the region vertically around the waveform */
  /* Other styles... */
}

/* Additional custom styles for regions
#waveform ::part(region) {
  background-color: rgba(0, 0, 100, 0.25) !important;
}*/


/* Custom styles for a specific region with id 'region-green'
#waveform ::part(region-green) {
  background-color: rgba(1, 245, 1, 0.25) !important;
} */

.prediction-labels {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.prediction-label {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.label-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
}
.label-text {
  font-weight: bold;
}

/* Ensure the container of the waveform and timeline has a defined width */
#waveform-container {
  width: 100%; /* or a specific pixel width */
  overflow: auto; /* Prevents content from overflowing */
}

/* Specific styles for the waveform and timeline to ensure they align */
#waveform, #timeline {
  width: 100%;
}